<template>
    <v-container class="d-flex flex-column gap-4">
      <div class="youtube-video-container">
        <iframe class="videoembed" width="auto" height="auto" src="https://www.youtube.com/embed/lnC0nzfQ9Ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <v-divider thickness="3" color="#d7935d" style="opacity: 100%;" class="my-3"></v-divider>
        <div class="youtube-video-container">

          <iframe class="videoembed" width="auto" height="auto" src="https://www.youtube.com/embed/qHfDZUYIpe8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      
      <v-divider thickness="3" color="#d7935d" style="opacity: 100%;" class="my-3"></v-divider>
      <div class="youtube-video-container">

        <iframe class="videoembed" width="auto" height="auto" src="https://www.youtube.com/embed/_bcg-z9TxtU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <v-divider thickness="3" color="#d7935d" style="opacity: 100%;" class="my-3"></v-divider>
      <div class="youtube-video-container">

        <iframe class="videoembed" width="auto" height="auto" src="https://www.youtube.com/embed/KdeTItJ9JsY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <v-divider thickness="3" color="#d7935d" style="opacity: 100%;" class="my-3"></v-divider>
      <div class="youtube-video-container">

        <iframe class="videoembed" width="auto" height="auto" src="https://www.youtube.com/embed/q6vvUvWwqPE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </v-container>
  </template>
  
  <style>
    .videoembed {
      max-height: 400;
    }

    .youtube-video-container {
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .youtube-video-container::after {
      display: block;
      content: "";
      padding-top: 56.25%;
    }

    .youtube-video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  </style>
  <script>
  // @ is an alias to /src
  
  export default {
    name: 'VideoView',
    components: {
    }
  }
  </script>
  