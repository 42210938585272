<template>
  <div class="d-flex flex-column gap-4">
    <h2 class="py-4"> How can we help you?</h2>
    <v-divider thickness="3" color="#d7935d" style="opacity: 100%;"></v-divider>
    <div class="py-4" style="text-align: justify;"> 
      Send us an email at <a href="mailto:hi@harkandheed.com">hi@harkandheed.com</a> or fill out this form if you have questions we can answer or are curious about commissioning custom ironworks. 
    </div>
    <v-form v-model="valid" class=" d-flex justify-center">
      <!-- <v-img
          style="height:500px"
          :width="200"
          :src="require('@/assets/logo.jpg')"
        ></v-img> -->
      <div class="d-flex flex-column gap-4" style=" width: 100%;">
        
        <div class="d-flex flex-row gap-4">

            <v-text-field
              class="mr-4"
              v-model="firstName"
              :rules="nameRules"
              label="First Name"
              required
            ></v-text-field>
          
            <v-text-field
              v-model="lastName"
              :rules="nameRules"
              label="Last Name"
              required
            ></v-text-field>
        </div>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>
    
        <v-textarea
          v-model="message"
          :rules="messageRules"
          label="Message"
          required
        ></v-textarea>
    
        <v-btn :disabled="!valid" color="#242f23" style="color: #efdac0  ;" type="submit" @click="submitForm()">Submit</v-btn>
      </div>
    </v-form>
  
    <div v-if="sent" class="pt-4">
      Your message has been received. Thank you! 
    </div>
  </div>

</template>
  
<style>
a {
  color: #242e23;
}
</style>

  <script>
  // @ is an alias to /src
  const WEB3FORMS_ACCESS_KEY = "a79c1736-6465-4c55-8c2a-f2a950cd66e6";
  export default {
    
    name: 'ContactView',
    components: {
    },
    data() {
      return {
        sent: false,
        valid: false,
        firstName: '',
        lastName: '',
        message: '',
        nameRules: [
          value => {
            if (value) return true

            return 'Name is requred.'
          },
        ],
        messageRules: [
          value => {
            if (value) return true

            return 'Message is requred.'
          },
        ],
        email: '',
        emailRules: [
          value => {
            if (value) return true

            return 'E-mail is requred.'
          },
          value => {
            if (/.+@.+\..+/.test(value)) return true

            return 'E-mail must be valid.'
          },
        ],
        };
    },
    methods: {
      async submitForm() {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: WEB3FORMS_ACCESS_KEY,
            name: this.firstName + " " + this.lastName,
            email: this.email,
            message: this.message,
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(result);
          this.sent = true;
        }
      },
    },
  }
  </script>
  