<template>
  <v-container
      class="bg-surface-variant my-6"
    >
    <div v-if="!$vuetify.display.mobile">
        <v-row
          class=""
        >
          <v-col cols="" class="py-0 d-flex" align-self="start">
            <v-img
              style="border-radius: 5px;"
              aspect-ratio="1/1"
              :src="images[15]"
              class="mb-2"
            ></v-img>
          </v-col>
          <v-divider thickness="3" color="#d7935d" style="opacity: 100%;"  vertical="true"></v-divider>
          <v-col class="py-0 d-flex" align-self="center">
            <p style="text-align: start;">Hello! We’re Bradley and Casey. Thank you for taking the time to look at our work. We sure are proud of it. Everything is made with intention and diligent attention to detail. We only want to spend our precious time, and Earth’s precious resources, making the highest quality products possible. 
            <br><br>
            Our hand-forged home goods are made by hand and with antique 19th-and-20th-century belt-driven machinery. Most of these tools were saved from rusting away in a field or from being melted down at auction. Bradley restored and adapted them to suit a modern workshop and believes they will last another couple hundred years with proper maintenance. They elegantly utilize basic physics to efficiently shape metal and wood into desired forms. These forms are then assembled to bring life to our designs, which have each evolved from items we initially made for ourselves that enhance our own experience of living sustainably. They are made to reduce our dependence on modern luxuries, made to bring beauty to your spaces, and made to last. 
            <br><br>
            Our herbs are grown without synthetic chemicals or tillage, on a quarter acre of rich clay soil of the Eno River Valley in Durham, NC, in a cove of mature hardwood trees that hosts a diverse ecosystem. Casey seeds, weeds, waters, harvests, and dries everything by hand, with love and reverence, and usually a baby on her back. Dried herbs are stored and sold as whole leaves in compostable, heat-sealed bags. These efforts result in herbs of the highest potency. 
            <br><br>
            Both the forge and the garden are a stone’s throw from our home on two little acres. We renovated the old 1940s 900sqft house here entirely by ourselves, with help from friends and family, gutting it down to the studs and joists. We have of course outgrown our space and are still searching for our next home on twenty acres or more in the foothills of the Blue Ridge Mountains. There, we will be able to greatly expand our production and build an all-natural timber frame home for our growing family. 
            <br><br>
            We yearn to use our many skills, new and old, to live in a way that is steeped in reciprocity with the Earth, sustaining all life for generations to come.
            </p>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row
          class=""
        >
        <v-col>
          <v-img
            style="border-radius: 5px;"
            aspect-ratio="1/1"
            max-height="500"
            :src="images[15]"
            class="mb-2"
          ></v-img>

          
          <v-divider thickness="3" color="#d7935d" style="opacity: 100%;" class="my-3"></v-divider>
          <p style="text-align: start;">Hello! We’re Bradley and Casey. Thank you for taking the time to look at our work. We sure are proud of it. Everything is made with intention and diligent attention to detail. We only want to spend our precious time, and Earth’s precious resources, making the highest quality products possible. 
            <br><br>
            Our hand-forged home goods are made by hand and with antique 19th-and-20th-century belt-driven machinery. Most of these tools were saved from rusting away in a field or from being melted down at auction. Bradley restored and adapted them to suit a modern workshop and believes they will last another couple hundred years with proper maintenance. They elegantly utilize basic physics to efficiently shape metal and wood into desired forms. These forms are then assembled to bring life to our designs, which have each evolved from items we initially made for ourselves that enhance our own experience of living sustainably. They are made to reduce our dependence on modern luxuries, made to bring beauty to your spaces, and made to last. 
            <br><br>
            Our herbs are grown without synthetic chemicals or tillage, on a quarter acre of rich clay soil of the Eno River Valley in Durham, NC, in a cove of mature hardwood trees that hosts a diverse ecosystem. Casey seeds, weeds, waters, harvests, and dries everything by hand, with love and reverence, and usually a baby on her back. Dried herbs are stored and sold as whole leaves in compostable, heat-sealed bags. These efforts result in herbs of the highest potency. 
            <br><br>
            Both the forge and the garden are a stone’s throw from our home on two little acres. We renovated the old 1940s 900sqft house here entirely by ourselves, with help from friends and family, gutting it down to the studs and joists. We have of course outgrown our space and are still searching for our next home on twenty acres or more in the foothills of the Blue Ridge Mountains. There, we will be able to greatly expand our production and build an all-natural timber frame home for our growing family. 
            <br><br>
            We yearn to use our many skills, new and old, to live in a way that is steeped in reciprocity with the Earth, sustaining all life for generations to come.
            </p>
        </v-col>
      </v-row>
      </div>

      
    </v-container>

 
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AboutView',
  data () {
    return {
        images: [
        require(`../assets/img/about/about_01.jpg`),
        require(`../assets/img/about/about_02.jpg`),
        require(`../assets/img/about/about_03.jpg`),
        require(`../assets/img/about/about_04.jpg`),
        require(`../assets/img/about/about_05.jpg`),
        require(`../assets/img/about/about_06.jpg`),
        require(`../assets/img/about/about_07.jpg`),
        require(`../assets/img/about/about_08.jpg`),
        require(`../assets/img/about/about_09.jpg`),
        require(`../assets/img/about/about_10.jpg`),
        require(`../assets/img/about/about_11.jpg`),
        require(`../assets/img/about/about_12.jpg`),
        require(`../assets/img/about/about_13.jpg`),
        require(`../assets/img/about/about_14.jpg`),
        require(`../assets/img/about/about_15.jpg`),
        require(`../assets/img/about/about_16.jpg`),
      ]
    }
  },
  mounted() {
    
  },
  components: {

  },
  methods:{
    
  }
}
</script>
