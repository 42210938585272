<template>
    <h2>
     Coming Soon!
    </h2>
  </template>
  
  <script>
  // @ is an alias to /src
  
  export default {
    name: 'ShopView',
    components: {
    }
  }
  </script>
  