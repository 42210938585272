<template>
  <v-container
      class="d-flex flex-column gap-4"
    >
    <v-carousel
      style="border-radius: 5px;"
      height="auto"
      show-arrows="hover"
      cycle
      hide-delimiter-background
    >
    <template v-slot:prev="{ props }">
      <v-btn
        variant="elevated"
        color="#242e23"
        style="color:#efdac0  ;"
        icon="mdi mdi-chevron-left"
        @click="props.onClick"
      ></v-btn>
    </template>
    <template v-slot:next="{ props }">
      <v-btn
        variant="elevated"
        icon="mdi mdi-chevron-right"
        color="#242e23"
        style="color:#efdac0  ;"
        @click="props.onClick"
      ></v-btn>
    </template>
      <v-carousel-item
        v-for="(item,i) in images"
        :key="i"
        :src="item"
      >
      </v-carousel-item>
    </v-carousel>

      <div class="d-flex flex-column gap-4">
          <h2 class="py-4"> Rediscovering time honored methods of stewardship and craft</h2>
          <v-divider thickness="3" color="#d7935d" style="opacity: 100%;"></v-divider>
          <p class="pt-4" style="text-align: justify;">Hark means to listen or hear, and heed means to pay careful attention to. 
            These describe the fundamental sensibility we find vital in the hard work of stewarding land and craft. 
            We first observe, discern, and behold the mysteries, patterns, and evolution of the plants, animals, tools, and their histories. 
            Then we focus our efforts to flow with the natural currents of water, wood grain, seasons, and gravity. For thousands of years humans all around the world lived this way. 
            They knew how to tend to the offerings of the Earth to make long-lasting, high-quality goods that were ultimately sustainable. 
            This ancient wisdom guides us as we cultivate our skills in forging and farming respectively. 
            We hope to soon sell our hand-forged ironworks and medicinal herbs here. 
            Follow along on your social media of choice and keep in touch for updates.</p>
      </div>

  </v-container>

</template>

<style>
  .flex-container {
    display: flex;
    flex-direction: row;
  }

  /* Responsive layout - makes a one column layout instead of a two-column layout */
  @media (max-width: 800px) {
    .flex-container {
      flex-direction: column;
    }
  }
</style>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
      images: [
        require(`../assets/img/home/carousel_01.jpg`),
        require(`../assets/img/home/carousel_02.jpg`),
        require(`../assets/img/home/carousel_03.jpg`),
        require(`../assets/img/home/carousel_04.jpg`),
        require(`../assets/img/home/carousel_05.jpg`),
        require(`../assets/img/home/carousel_06.jpg`),
        require(`../assets/img/home/carousel_07.jpg`),
        require(`../assets/img/home/carousel_08.jpg`),
        require(`../assets/img/home/carousel_09.jpg`),
        require(`../assets/img/home/carousel_10.jpg`),
      ]
    }
  },
}
</script>
