<template>
  <v-layout style="min-height: 100%;" class="d-flex flex-column gap-4">
    <v-main style="min-height: calc(100vh - 230px);" >
      <div v-if="$vuetify.display.mobile" style="width:100%" class="d-flex">
        <v-btn
        elevation="0"
          style="background-color: transparent;"
        >
          <v-icon size="x-large">mdi mdi-menu</v-icon>

          <v-dialog
            v-model="dialog"
            fullscreen
            activator="parent"
            transition="dialog-bottom-transition"
          >
            <v-card style="background-color: #242f23;" class="text-center">
              <v-list style="background-color: transparent;">
                <v-list-item
                  v-for="page in pages"
                  :key="page.text"
                  :value="page"
                >
                  <v-list-item-title style="color:#efdac0  ; font-family: 'Courier New', Courier, monospace;" @click="$router.push(page.href); dialog = false">{{ page.text.toUpperCase() }}</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-btn style="font-family: 'Courier New', Courier, monospace;" color="#efdac0  " block @click="dialog = false"><v-icon>mdi mdi-close</v-icon> Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
      </div>

      <v-container fluid class="text-center d-flex flex-column justify-center align-center" style="height:100%; max-width: 1200px;">
        <v-img
          :width="700"
          fill
          :src="require('@/assets/logo_horiz.png')"
          
        ></v-img>
        <div v-if="!$vuetify.display.mobile" class="d-flex flex-row mt-2">
          <v-btn
          v-for="page in pages"
            :key="page.text"
            :style="{ 'border-bottom': $route.name == page.text ? 'solid 2px' : '' }"
            color="#242f23"
            size="large"
            style="font-weight:600; border-radius: 0px; font-size: 18px;"
            variant="text"
            @click="$router.push(page.href)"
          >
            {{ page.text }}
          </v-btn>
        </div>

        <router-view class="pt-2" style="height:100%">
          
        </router-view>
      </v-container>
      
      
      
    </v-main>
    <v-footer
      style="
        padding-top: 25px;
        background-color: #242f23; 
        width: 100%; 
        height: 231px;"
      class="text-center d-flex flex-column footer"
    > 
    <div class="d-flex flex-row">
      <v-img
      aspect-ratio="1/1"
        :width="120"
        :src="require('@/assets/logo_small.png')"
        
      ></v-img>
      <div  class="d-flex flex-column justify-start align-start gap-1" >
        <v-btn
        color="#efdac0  "
        height="25"
        v-for="page in pages"
        :key="page.text"
        variant="text"
        @click="$router.push(page.href)"
        >
        {{ page.text }}
        </v-btn>
      </div>
      </div>
      <div>
        <v-btn
          color="#efdac0  "
          v-for="icon in icons"
          :key="icon.icon"
          :icon="icon.icon"
          variant="text"
          :href="icon.href"
        ></v-btn>
      </div>

      <v-divider></v-divider>

      <div style="color:#efdac0  ">
        © {{ new Date().getFullYear() }} — <strong>Hark and Heed</strong>
      </div>
    </v-footer>
    
  </v-layout>

    
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data () {
      return {
        dialog: false,
        tab: null,
        items: [
          'Home', 'Shop', 'Videos', 'Portfolio', 'Contact',
        ],
        pages: [
          {"text": 'Home',
           "href": "/home"},
           {"text": 'About',
           "href": "/about"},
          //  {"text": 'Portfolio',
          //  "href": "/portfolio"},
           {"text": 'Shop',
           "href": "/shop"},
           {"text": 'Videos',
           "href": "/videos"},
           {"text": 'Contact',
           "href": "/contact"}
        ],
        icons: [
          {"icon": 'mdi-facebook',
           "href": "https://www.facebook.com/harkandheed"},
           {"icon": 'mdi-instagram',
           "href": "https://instagram.com/harkandheed/"},
           {"icon": 'mdi-youtube',
           "href": "https://www.youtube.com/channel/UCGmVs9wCp83KqALTVqw2Y3w"}
        ]
      }
    },
}
</script>

<style>
#app {
  font-family: 'Comfortaa', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #242e23;
}


html, body {
    min-height: 100%;
    background-color: #efdac0  ;
}

/* body::after {
  content: '';
  display: block;
  height: 230px; 
} */


</style>
